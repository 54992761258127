import { gql } from "@apollo/client";
import gqlFile from "graphql-tag";

export const CREATE_COMPANY_QUERY = gql`
  mutation CreateCompany(
    $name: String!
    $internalReference: String
    $accountingFirm: ID!
    $vatNumber: String!
    $software: ID
    $ssiType: String
  ) {
    createCompany(
      input: {
        name: $name
        internalReference: $internalReference
        accountingFirm: $accountingFirm
        vatNumber: $vatNumber
        software: $software
        ssiType: $ssiType
      }
    ) {
      errors {
        field
        messages
      }
      company {
        id
        name
        internalReference
        currency
        accountingFirm {
          id
          name
        }
        vatNumber
        businessCode
        commercialRegisterNumber
        email
        phone1
        phone2
        fax
        address1
        address2
        address3
        postalCode
        city
        software {
          id
          name
          software
        }
        country {
          name
          code
        }
        logo
      }
    }
  }
`;

export const GET_EXPORTS = gql`
  query CompanyLastExports($id: ID!, $quantity: Int) {
    listCompanyExports(id: $id, quantity: $quantity) {
      url
      created
      name: fileName
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: CompanyUpdateInput!) {
    updateCompany(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_OPTIONS_SSI_TYPE = gql`
  query {
    ssiType: __type(name: "CompanySettingSsiType") {
      enumValues {
        name
      }
    }
  }
`;
export const GET_CEGID_VAT_OPTIONS = gql`
  query {
    codes: __type(name: "CegidVatCodeChoises") {
      enumValues {
        name
      }
    }
    regimes: __type(name: "CegidVatRegimeChoises") {
      enumValues {
        name
      }
    }
  }
`;

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation updateCompanySettings($input: CompanySettingInput!) {
    updateCompanySettings(input: $input) {
      companySettings {
        invoiceReportNotification
        software {
          id
          name
        }
        ssiType
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($input: CompanyDeleteInput!) {
    deleteCompany(input: $input) {
      errors {
        field
        messages
      }
      success
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      internalReference
      internalSocialReference
      currency
      accountingFirm {
        name
        id
      }
      vatNumber
      businessCode
      shortName
      creationDate
      timeLength
      capitalAmount
      nii: intracomVatNr
      email
      phone1
      phone2
      fax
      website
      paymentPath
      legalCategory {
        code
        name
      }
      legalStructure {
        code
        name
      }
      businessActivity {
        code
        name
      }
      logo
      createdAt
      updatedAt
      createdBy
      updatedByUser {
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_COMPANY_SETTINGS = gql`
  query getCompanySettings($id: ID!) {
    getCompanySettings(id: $id) {
      invoiceReportNotification
      software {
        id
        name
        software
        apiUrl
        importExportAuto
      }
      ssiType
      emailsAllowedUpload
      isAccountingFirmCompany
      halfDoesNeoscan
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: Int) {
    getUser(id: $id) {
      id
      email
    }
  }
`;
export const GET_CURRENT_CYCLE = gql`
  query getCurrentCycle($company: ID!) {
    getCurrentCycle(company: $company) {
      id
      startDate
      endDate
      inUse
    }
  }
`;

export const LIST_COMPANIES_PAGINATED_QUERY = gql`
  query listCompaniesPaginated(
    $accountingFirm: ID
    $search: String
    $limit: Int
    $offset: Int
    $orderBy: [String]
    $settings_SsiType_In: [String]
    $settings_Software_Software_In: [String]
    $id_Istartswith: String
    $internalReference_Istartswith: String
    $vatNumber_Istartswith: String
    $name_Icontains: String
    $id_In: [ID]
  ) {
    listCompaniesPaginated(
      filter: {
        accountingFirm: $accountingFirm
        nameOrInternalReference: $search
        settings_SsiType_In: $settings_SsiType_In
        settings_Software_Software_In: $settings_Software_Software_In
        id_Istartswith: $id_Istartswith
        internalReference_Istartswith: $internalReference_Istartswith
        vatNumber_Istartswith: $vatNumber_Istartswith
        name_Icontains: $name_Icontains
        id_In: $id_In
      }
    ) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
        internalReference
        currency
        accountingFirm {
          id
          name
        }
        vatNumber
        businessCode
        commercialRegisterNumber
        email
        phone1
        phone2
        fax
        address1
        address2
        address3
        postalCode
        city
        country {
          name
          code
        }
        logo
        settings {
          ssiType
          software {
            id
            name
            software
          }
        }
        addresses {
          city
          postalCode
        }
      }
    }
  }
`;

export const UPDATE_COUNTERPART = gql`
  mutation UpdateCounterpart(
    $name: String!
    $shortName: String
    $vatNumber: String
    $isCollective: Boolean
    $isPeriodic: Boolean
    $hasQuantityTracking: Boolean
    $hasQuantityTracking2: Boolean
    $hasCurrencyTracking: Boolean
    $currency: String!
    $country: ID!
    $company: ID!
    $vat: ID
    $isActive: Boolean
    $id: ID
  ) {
    updateCounterpart(
      input: {
        name: $name
        shortName: $shortName
        vatNumber: $vatNumber
        isCollective: $isCollective
        isPeriodic: $isPeriodic
        hasQuantityTracking: $hasQuantityTracking
        hasQuantityTracking2: $hasQuantityTracking2
        hasCurrencyTracking: $hasCurrencyTracking
        currency: $currency
        country: $country
        company: $company
        vat: $vat
        isActive: $isActive
        id: $id
      }
    ) {
      counterPart {
        name
        shortName
        vatNumber
        code
        isCollective
        isPeriodic
        hasQuantityTracking
        hasQuantityTracking2
        hasCurrencyTracking
        currency
        country {
          code
          name
        }
        vat {
          rate
          id
        }
        isActive
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const IMPORT_PLATFORM = gqlFile`
  mutation(
    $company: String!,
    $file: Upload
    $auto: Boolean
  ){
    importPlatform(
      input: {
        company: $company,
        file: $file,
        auto: $auto
      }
    ){
      success
      errors {
        field
        messages
      }
      summary {
        company {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        clients {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        providers {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        counterparts {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        vats {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        books {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        subsidiaries {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
        subsidiaryTypes {
          errors {
            primaryField
            value
            list {
              field
              messages
            }
          }
          warnings
          imported
          skipped
          created
          updated
        }
      }
    }
  }
`;

export const LIST_LEGAL_STRUCTURES = gql`
  query listLegalStructures($search: String, $limit: Int, $offset: Int, $orderBy: [String]) {
    listLegalStructures(filter: { search: $search }) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        code
        name
      }
    }
  }
`;

export const LIST_LEGAL_CATEGORIES = gql`
  query listLegalCategories($search: String, $limit: Int, $offset: Int, $orderBy: [String]) {
    listLegalCategories(filter: { search: $search }) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        code
        name
      }
    }
  }
`;

export const LIST_BUSSINESS_ACTIVITIES = gql`
  query listBusinessActivities($search: String, $limit: Int, $offset: Int, $orderBy: [String]) {
    listBusinessActivities(filter: { search: $search }) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        code
        name
      }
    }
  }
`;

export const LIST_COMPANIES_FOR_SELECT = gql`
  query listCompaniesPaginated(
    $accountingFirm: ID
    $search: String
    $limit: Int
    $offset: Int
    $settings_SsiType_In: [String]
  ) {
    listCompaniesPaginated(
      filter: {
        accountingFirm: $accountingFirm
        nameOrInternalReference: $search
        settings_SsiType_In: $settings_SsiType_In
      }
    ) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: ["name"]) {
        id
        name
        internalReference
        logo
        accountingFirm {
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_FOR_SELECT = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      internalReference
    }
  }
`;

export const CREATE_COMPANY_BANK = gql`
  mutation createCompanyBank(
    $company: ID!
    $iban: String
    $bic: String
    $currency: String
    $bankDescription: String
    $sepaCredentialIdentifier: String
    $isMain: Boolean
    $isActive: Boolean
    $name: String
  ) {
    createCompanyBank(
      input: {
        company: $company
        iban: $iban
        bic: $bic
        currency: $currency
        bankDescription: $bankDescription
        sepaCredentialIdentifier: $sepaCredentialIdentifier
        isMain: $isMain
        isActive: $isActive
        name: $name
      }
    ) {
      companyBankAccount {
        id
        iban
        bic
        bankDescription
        counterpart {
          id
          name
          code
        }
        book {
          id
          name
          code
        }
        isMain
        isActive
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_COMPANY_IMPORT_EXPORT = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      software {
        name
        apiUrl
        id
        software
      }
      internalReference
    }
  }
`;

export const GET_COMPANY_SOFTWARE = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      software {
        name
        apiUrl
        id
        software
        importExportAuto
      }
      internalReference
    }
  }
`;

export const LIST_COMPANY_BANKS = gql`
  query listCompanyBankPaginated(
    $filter: CompanyBankFilterInput
    $limit: Int
    $offset: Int
    $orderBy: [String]
  ) {
    listCompanyBankPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        iban
        bic
        bankDescription
        name
        sepaCredentialIdentifier
        isMain
        isActive
      }
    }
  }
`;

export const LIST_BANKS = gql`
  query listBanks($filter: BankFilter, $limit: Int, $offset: Int, $orderBy: [String]) {
    listBanks(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
      }
    }
  }
`;

export const GET_MAX_MIN_CYCLES = gql`
  query maxMinCyclesDates($company: ID) {
    maxMinCyclesDates(company: $company) {
      maxEndDate
      maxLimitDate
      maxStartDate
      minEndDate
      minLimitDate
      minStartDate
    }
  }
`;

export const GET_COMPANY_WITH_ACCOUNTING_FIRM = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      internalReference
      accountingFirm {
        id
        name
      }
    }
  }
`;
