import { useLazyQuery } from "@apollo/client";
import { useOnErrorGraphql } from "@newrai/ant-design";
import { clientCentralApi } from "@newrai/api-clients";
import { routers } from "config/routes";
import useStore from "config/store";
import { GET_COMPANY_WITH_ACCOUNTING_FIRM } from "queries/centralApi/companies";

export function useQueryCompanyAccountingFirm() {
  const { onErrorGraphql } = useOnErrorGraphql();
  const setCompanySelected = useStore(state => state.setCompanySelected);

  const [companyWithAccountingFirm, { data }] = useLazyQuery<
    CompanyWithAccountingFirmOutput,
    CompanyWithAccountingFirmOnput
  >(GET_COMPANY_WITH_ACCOUNTING_FIRM, {
    client: clientCentralApi,
    onError: onErrorGraphql,
    onCompleted: ({ getCompany }) => {
      setCompanySelected({
        accountingFirm: getCompany.accountingFirm,
        accountingFirmId: getCompany?.accountingFirm?.id,
        company: getCompany,
        companyId: getCompany.id,
      });
      routers.navigate(`/company/${getCompany.id}`, { replace: true });
    },
  });

  return {
    companyWithAccountingFirm,
    data,
  };
}
